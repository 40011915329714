<template>
    <div>
        <Datepicker
            v-model="modelDate"
            :id="`date-${id}`"
            :ref="config?.ref ?? 'datepickerFormat'"
            :lang="config?.language ?? 'ja'"
            :input-class="`form-control date__time--format ${config?.error ? 'is-invalid' : ''} ${disabled ? 'disabled_cursor' : ''} ${config?.classCustom ?? ''}`"
            :value-type="config?.valueType ?? 'format'"
            :format="config?.format ?? 'YYYY-MM-DD'"
            :placeholder="config?.placeholder ?? 'YYYY-MM-DD'"
            :disabled-date="notBeforeToday"
            :disabled="disabled ?? false"
            @change="handleOnChangeDate"
        />
        <div v-if="config?.error" class="invalid-feedback d-block">
            <span v-if="config?.isRequired">{{
                $t('validateField.requiredDate', {
                    field: config.errorField
                })
            }}</span>
            <span v-if="config?.errorText">{{ config.errorText }}</span>
        </div>
    </div>
</template>

<script>
import Datepicker from 'vue2-datepicker';
import { STR_DATE } from '@/utils/format';
export default {
    name: 'DateFormat',
    components: {
        Datepicker
    },
    props: {
        id: {
            type: [String, null],
            default: ''
        },
        model: {
            type: [String, null],
            default: ''
        },
        config: {
            type: Object,
            default: () => {
                return {
                    ref: '',
                    format: STR_DATE,
                    valueType: 'format',
                    language: 'ja',
                    placeholder: STR_DATE,
                    disabledBeforeToday: false,
                    isRequired: false,
                    error: false,
                    errorText: '',
                    valueSync: '',
                    classCustom: ''
                };
            }
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            modelDate: ''
        };
    },
    methods: {
        handleOnChangeDate(date) {
            let config = this.config;
            config.error = false;
            this.modelDate = date;
            config.isRequired = false;
            config.valueSync = date;

            this.$emit('update:model', this.modelDate);
            this.$emit('update:config', config);
            this.$emit('change', date);
        },

        notBeforeToday(date) {
            if (this.$props.config.disabledBeforeToday) {
                return date < new Date(new Date().setHours(0, 0, 0, 0));
            }
            return false;
        }
    },
    watch: {
        model: {
            handler: function (valModel) {
                let config = this.config;
                config.valueSync = valModel ?? '';
                this.modelDate = valModel ?? '';
            },
            immediate: true
        },
        modelDate: {
            handler: function (valModelDate) {
                let config = this.config;
                config.valueSync = valModelDate;
                if (!valModelDate) {
                    this.$emit('remove');
                }
                this.$emit('update:config', config);
                this.$emit('update:model', valModelDate);
            }
        },
        config: {
            handler: function (valConfig) {
                this.config = valConfig;
                this.$emit('update:config', this.config);
            },
            immediate: true,
            deep: true
        }
    }
};
</script>

<style lang="scss">
.form-control:disabled.date__time--format.disabled_cursor,
.form-control[readonly].date__time--format.disabled_cursor {
    background-color: #f7f7f7;
}
</style>
